import styled from "styled-components";

import { deviceQueries } from "../../common.styled";

export const ProjectsSection = styled.section`
    h2 {
        counter-increment: title 4;
    }

    .projects__item {
        display: block;
        margin: 4rem 0;

        &__img {
            position: relative;

            a {
                display: block;
                width: 100%;
                position: relative;
                border-radius: var(--border-radius);
                overflow: hidden;
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

                .projects__item__img--linkicon {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -1rem);
                    z-index: 1;
                    padding: 1rem;
                    background-color: var(--light-navy);
                    border-radius: 50%;
                    opacity: 0;
                    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

                    img {
                        width: 2rem;
                        display: block;
                        transform: translate(3px, -1px);
                    }
                }

                .projects__item__img--main {
                    width: 100%;
                    display: block;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    background-color: var(--green);
                    opacity: 0.5;
                    height: 100%;
                    width: 100%;
                }

                &:hover {
                    transform: scale(1.01);

                    .projects__item__img--linkicon {
                        opacity: 1;
                        transform: translate(-50%, -50%);
                        transition: all .15s cubic-bezier(0.645, 0.045, 0.355, 1) .1s;
                    }
                }
            }
        }

        &__content {
            text-align: center;
            max-width: calc(100% - 2rem);
            background-color: var(--light-navy);
            margin: auto;
            padding: 1rem;
            transform: translateY(-2rem);
            border-radius: var(--border-radius);
            z-index: 1;

            .featured {
                display: block;
                margin: .625rem 0px;
                color: var(--green);
                font-family: var(--font-mono);
                font-size: var(--fz-xs);
                font-weight: 400;
            }

            h3 {
                font-size: clamp(24px, 5vw, 28px);
                margin-bottom: 1.25rem;
                transition: var(--transition);

                &:hover {
                    color: var(--green);                
                }
            }

            &--description {
                box-shadow: none;
                transition: var(--transition);
                position: relative;
                z-index: 2;
                padding: 25px;
                border-radius: var(--border-radius);
                color: var(--light-slate);
                font-size: var(--fz-lg);

                p {
                    margin: 0;
                }
            }

            ul {
                display: flex;
                list-style: none;
                gap: 1.25rem;
                justify-content: center;
                color: var(--light-slate);
                font-family: var(--font-mono);
                font-size: var(--fz-xs);
                white-space: nowrap;
                flex-wrap: wrap;
                margin-top: 1.5rem;
                padding: 0;
            }
        }

        &:nth-child(odd) {
            .projects__item {
                &__img {
                    transform: translateX(0);
                }
            }
        }

        @media ${deviceQueries.laptop} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            text-align: right;
            margin: 6rem 0;

            &__img {
                transform: translateX(0);

                a {
                    width: 120%;
                }
            }

            &__content {
                background-color: transparent;
                text-align: right;
                padding: 0;
                transform: translateY(0);

                &--description {
                    box-shadow: 0 10px 30px -15px var(--navy-shadow);
                    background-color: var(--light-navy);
                }

                ul {   
                    justify-content: flex-end;
                }
            }

            &:nth-child(odd) {
                .projects__item {
                &__img {
                    transform: translateX(-20%);
                }
            
                &__content {
                    order: -1;
                    text-align: left;

                    ul {
                        justify-content: flex-start;
                    }
                }
            }
            }
        }
    }

    .otherprojects {
        > ul {
            display: grid;
            gap: 1rem;
            list-style: none;
            padding: 0;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            > li {
                background-color: var(--light-navy);
                border-radius: var(--border-radius);
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

                a {
                    display: block;
                    padding: 2rem 1.75rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;

                    svg {
                        color: var(--green);
                        width: 2rem;
                        margin-bottom: 0.5rem;
                        fill: none;
                    }

                    h3 {
                        margin-bottom: .625rem;
                        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }

                    p {
                        margin-bottom: 1.25rem;
                    }

                    ul {
                        padding: 0;
                        list-style: none;
                        display: flex;
                        gap: 1rem;
                        justify-content: flex-start;
                        font-family: var(--font-mono);
                        font-size: var(--fz-xxs);
                        white-space: nowrap;
                        flex-wrap: wrap;
                        line-height: .2;
                    }
                }

                &:hover {
                    transform: translateY(-0.5rem);

                    h3 {
                        color: var(--green)
                    }

                    svg {
                        fill: var(--green)
                    }
                }
            }
        }
    }
`