import { useContext } from "react";

import { SkillsSection } from "../styled/sections/Skills.styled";

import { Text, LanguageContext } from "../containers/Language";

import SkillItem from "../components/SkillItem";

import SkillsList from "../data/skills.json";

export default function Skills() {
  const { dictionary } = useContext(LanguageContext)

  return (
    <SkillsSection>
      <h2>Skills</h2>
      <div className="skills-grid">
        {SkillsList.map((item, idx) => {
          return <SkillItem key={idx} title={item.name} grade={item.grade} />
        })}
      </div>
    </SkillsSection>
  );
}
