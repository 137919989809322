import { useContext } from 'react'

import { ProjectsSection } from "../styled/sections/Projects.styled";

import { Text, LanguageContext } from "../containers/Language";

import LinkIcon from "../assets/img/svg/imagelink-icon.svg"

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../assets/img', false, /\.(png|jpe?g|svg)$/));

export default function Projects() {
  const { dictionary } = useContext(LanguageContext)

  return (
    <ProjectsSection id="section-projects" className="projects">
      <h2 className="numbered">{dictionary.projects.sectionTitle}</h2>
      {dictionary.projects.projectItems.map((item, idx) => {
        if(item.highlighted) return (
          <div id={`work-content-item${idx}`} key={idx} className="projects__item">
            <div className='projects__item__img'>
              <a href={item.link}>
                <div className='projects__item__img--linkicon'>
                  <img src={LinkIcon} />
                </div>
                <img className='projects__item__img--main' src={images[item.img]} />
              </a>
            </div>
            <div className='projects__item__content'>
              <span className='featured'>Featured Project</span>
              <h3>
                <a href={item.link}>{item.name}</a>
              </h3>
              <div className='projects__item__content--description'>
                <p>{item.desc}</p>
              </div>
              <ul>
                {item.techs.map((listitem, idy) => {
                  return <li key={idy}>{listitem}</li>
                })}
              </ul>
            </div>
          </div>
        )
      })}
      <h2 className="centered">Other Noteworthy Projects</h2>
      <div className='otherprojects'>
        <ul>
          {dictionary.projects.projectItems.map((item, idx) => {
            if(!item.highlighted) return (
              <li key={idx} className='otherprojects__item'>
                <a href={item.link} target="_blank">
                  <div className='otherprojects__item--main'>
                    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-folder"><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
                    <h3>{item.name}</h3>
                    <p>{item.desc}</p>
                  </div>
                  <div className='otherprojects__item--techs'>
                    <ul>
                      {item.techs.map((listitem, idy) => {
                        return <li key={idy}>{listitem}</li>
                      })}
                    </ul>
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </ProjectsSection>
  );
}
