import styled from "styled-components";

import { deviceQueries } from "../../common.styled";

export const WorkSection = styled.section`
  max-width: 700px;
  margin: auto;
  
  h2 {
    counter-increment: title 3;
  }

  .work-block {
    display: block;

    &__tabs {
      font-family: var(--font-mono);    
      position: relative;
      font-size: var(--fz-xs);
      position: relative;
      max-width: calc(100% - 20px);
      margin: auto;
      margin-bottom: 2rem;

      &--scroll {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 2rem;
        height: 2rem;

        &.scroll-left {
          left: -2rem;
        }

        &.scroll-right {
          right: -2rem;
        }

        svg {
          fill: var(--lightest-slate);
          height: .8rem;
        }
      }

      &--container {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          overflow-x: auto;
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          scrollbar-width: none;  /* Firefox */
          position: relative;
          scroll-behavior: smooth;
    
          &::-webkit-scrollbar { 
              display: none;  /* Safari and Chrome */
          }

          li {
            border-bottom: 2px solid var(--lightest-navy);

            button {
              height: var(--tab-height);
              width: var(--tab-width);
              padding: 0;
              text-align: center;
              border-width: 0;
              color: var(--slate);

              &:hover {
                color: var(--green);
              }
            }
          }          
        }

        &--marker {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: var(--tab-width);
          background-color: var(--green);
          transform: translateX(calc(${(props) => props.activeSlide} * var(--tab-width)));
          transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition-delay: 0.1s;
        }
      }
    }

    &__content {
      width: 100%;

      &--title {
        margin-bottom: 1rem;
      }

      &--item {
        padding: 10px .5rem;
        display: none;

        &.active {
          display: block;
        }
      }

      &--timerange {
        margin-bottom: 25px;
        color: var(--light-slate);
        font-family: var(--font-mono);
        font-size: var(--fz-xs);
      }

      ul {
        padding-left: 0;
        list-style: none;
        font-size: var(--fz-lg);

        li {
          position: relative;
          padding-left: 2rem;
          margin-bottom: .625rem;

          &::before {
            content: "▹";
            top: 0;
            left: 0;
            position: absolute;
            color: var(--green);
            font-size: var(--fz-lg);
            margin-right: .5rem;
          }
        }
      }
    }

    @media ${deviceQueries.laptop} {
      display: flex;

      &__tabs {
        width: 10rem;
        margin: unset;

        &--scroll {
          display: none;
        }

        &--container {
          ul {
            display: block;
            width: 8.5rem; 
            
            li {
              border-bottom: none;
              border-left: 2px solid var(--lightest-navy);

              button {
                width: 100%;
                padding: 0 1rem;
                text-align: left;
              }
            }
          }

          &--marker {
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: var(--tab-height);
            background-color: var(--green);
            transform: translateY(calc(${(props) => props.activeSlide} * var(--tab-height)));
            transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition-delay: 0.1s;
          }
        }
      }

      &__content {
        width: 100%;

        &--title {
          margin-bottom: 2rem;
        }
        
        &--item {
          padding: 10px 5px 10px 40px;
        }
      }
    }

  }
`;
