import { FooterSection } from "../styled/sections/Footer.styled";

import { useContext } from "react";

import { LanguageContext } from "../containers/Language";

import Profile from "../assets/img/profile.jpg";

export default function Footer() {
  const { dictionary } = useContext(LanguageContext)

  return (
    <FooterSection>
      <span>{dictionary.footer}</span>
    </FooterSection>
  );
}
