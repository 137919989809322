import styled from "styled-components";

export const FooterSection = styled.footer`
    padding: 2rem;
    transition: var(--transition);

    span {
        color: var(--light-slate);
        font-family: var(--font-mono);
        font-size: var(--fz-xxs);
        line-height: 1;
        text-align: center;
        display: block;
    }
`