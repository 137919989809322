import styled from "styled-components";

export const SkillItemStyled = styled.div`
    font-size: 1.2rem;
    font-family: var(--font-mono);

    .skill-data {
        display: flex;
        justify-content: space-between;

        .title {
            margin-bottom: .25rem;
        }

        .value {
            color: var(--green);
            opacity: ${props => props.value / 100};
        }
    }

    .grade-bar {
        width:100%;
        height: 4px;
        background-color: var(--dark-slate);
        position: relative;

        &::after {
            content: '';
            position: absolute;
            display: block;
            background-color: var(--green);
            top: 0;
            left: 0;
            height: 100%;
            width: ${props => props.value}%;
            opacity: ${props => props.value / 100};
        }
    }
`;