import styled from "styled-components";

import { deviceQueries } from "../../common.styled";

export const ContactSection = styled.section`
    text-align: center;

    .pretitle {
        counter-increment: title 4;
        font-family: var(--font-mono);
        color: var(--green);
        font-size: var(--fz-md);
        margin: 0.5rem auto 1.25rem;
        display: block;
    }

    h2 {
        font-size: clamp(40px, 5vw, 60px);
        margin-bottom: 1.25rem;
    }

    p {
        max-width: 40rem;
        margin: auto;
        margin-bottom: 3rem;
    }

    #contactform {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .input__container {
            flex: 0 0 100%;

            input,
            textarea {
                width: 100%;
                padding: 1rem 1.25rem;
                font-size: var(--fz-md);
                font-family: var(--font-mono);
                background-color: var(--light-navy);
                border-width: 0;
                border-radius: var(--border-radius);
                color: var(--light-slate);

                &:focus,
                &:focus-visible {
                    outline: 1px solid var(--green);
                }

                &::placeholder {
                    color: var(--light-slate);
                    opacity: .6;
                }

                &[type=submit] {
                    background-color: var(--dark-navy);
                    width: unset;
                }
            }

            textarea {
                resize: none;
            }
        }
    }

    @media ${deviceQueries.laptop} {
        form {
            .input__container {
                &.half {
                    flex: 0 0 calc(50% - .5rem);
                }
            }
        }
    }
`;
