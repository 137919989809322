import styled from "styled-components";

export const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 100px 0;

  .mynameis {
    font-family: "Fira Mono", monospace;
    color: var(--green);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    margin: 0 0 20px 2px;
    display: block;
  }

  h1,
  h2 {
    font-size: clamp(2.5rem, 6vw, 4.3rem);
  }

  h2 {
    color: var(--slate);
    line-height: 1.1;
  }

  p {
    margin-top: 20px;
  }

  a {
    margin-top: 3rem;
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 1.25rem 1.75rem;
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
    display: inline-block;

    &:hover {
      background-color: var(--green-tint);
    }
  }

  @media screen and (max-width: 768px) {
    .hero-container > * {
      margin-bottom: .5rem;
    }

    a {
      margin-top: 1rem;
    }
  }
`;
