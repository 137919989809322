import styled from "styled-components";

import { deviceQueries } from "../../common.styled";

export const AboutSection = styled.section`
  h2 {
    counter-increment: title 1;
  }

  .hero-section__block {
    display: block;
    width: 100%;

    &--text {
      margin-bottom: 2rem;
      
      ul {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px 10px;
        padding: 0px;
        margin: 20px 0px 0px;
        overflow: hidden;
        list-style: none;
        padding: 1rem 1rem .25rem;
        background-color: var(--light-navy);

        li {
          position: relative;
          margin-bottom: .75rem;
          font-family: var(--font-mono);
          font-size: var(--fz-xs);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: .5rem;

          .flags {
            display: flex;
            flex-wrap: nowrap;
            gap: .4rem;

            img {
              width: 1rem;
            }
          }

          &::before {
            content: "▹";
            color: var(--green);
            font-size: var(--fz-sm);
            line-height: .75rem;
            transform: translateY(-1px);
          }
        }
      }
    }

    &--img {
      &-container {
        margin: auto;
        width: 90%;
        padding: 0 1.5rem 1.5rem 0;
        position: relative;
        max-width: 20rem;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: var(--border-radius);
          overflow: hidden;
        }

        &::before {
          content: '';
          background-color: var(--green);
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 1.5rem);
          height: calc(100% - 1.5rem);
          opacity: .5;
          border-radius: var(--border-radius);
          overflow: hidden;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          border: 2px solid var(--green);
          width: calc(100% - 1.5rem);
          height: calc(100% - 1.5rem);
          z-index: -1;
          border-radius: var(--border-radius);
        }
      }
    }

    @media ${deviceQueries.laptop} {
      display: grid;
      grid-template-columns: 3fr 2fr;
      gap: 3rem;

      &--text {
        margin-bottom: 0;

        ul {
          
        grid-template-columns: repeat(2, minmax(140px, 100%));
        }
      }
    }
  }
`;
