import { useState, useContext, useRef } from 'react'
import emailjs from '@emailjs/browser';

import { ContactSection } from "../styled/sections/Contact.styled";

import { Text, LanguageContext } from "../containers/Language";

import Profile from "../assets/img/profile.jpg";

export default function Contact() {
  const [mailData, setMailData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  })
  const contactForm = useRef();

  const { dictionary } = useContext(LanguageContext)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_2cl5g2n', 'template_36i6nfl', contactForm.current, {
        publicKey: 'OCLOgdqiOhqx9gNxh',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }

  return (
    <ContactSection id="section-contact">
      <span className="pretitle">{dictionary.contact.subtitle}</span>
      <h2>{dictionary.contact.sectionTitle}</h2>
      <p>{dictionary.contact.desc}</p>
      <form ref={contactForm} onSubmit={sendEmail} id="contactform">
        <div id="form__name" className="half input__container">
            <input className="input" type="text" name="name" onInput={(e) => setMailData({...mailData, name: e.target.value})} placeholder={dictionary.contact.placeholders.name} autoComplete="off" />
        </div>
        <div id="form__email" className="half input__container">
            <input className="input" type="email" name="email" onInput={(e) => setMailData({...mailData, email: e.target.value})} placeholder={dictionary.contact.placeholders.email} autoComplete="off" />
        </div>
        <div id="form__subject" className="input__container">
            <input className="input" type="text" name="subject" onInput={(e) => setMailData({...mailData, subject: e.target.value})} placeholder={dictionary.contact.placeholders.subject} autoComplete="off" />
        </div>
        <div id="form__message" className="input__container">
        <textarea className="input" rows="8" name="message" onInput={(e) => setMailData({...mailData, message: e.target.value})} placeholder={dictionary.contact.placeholders.message} autoComplete="off"></textarea>
        </div>
        <div id="form__submit" className="input__container">
            <input id="submit" type="submit" value={dictionary.contact.submit} />
        </div>
      </form>
    </ContactSection>
  );
}
