import { useContext } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Briefcase from "../assets/img/svg/briefcase.svg"

import { ReviewsSection } from "../styled/sections/Reviews.styled";

import { Text, LanguageContext } from "../containers/Language";

// import { Text, LanguageContext } from "../containers/Language";

import SkillsList from "../data/skills.json";

export default function Reviews() {
  const { dictionary } = useContext(LanguageContext)

  console.log(useContext(LanguageContext))

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true
        }
      },
    ]
  };

  return (
    <ReviewsSection>
      <h2>Reviews</h2>
      <Slider {...settings}>
          {dictionary.reviews.map((item, idx) => {
            return (
              <div key={idx} className="reviews-slide">
                <div className="reviews-slide--titleblock">
                  <img src={Briefcase} className="reviews-slide--titleblock-icon" />
                  <div className="reviews-slide--titleblock-text">
                    <h3>{item.author}</h3>
                    <span><b>{item.position}</b> <i>{item.company && `@${item.company}`}</i></span>
                  </div>
                </div>
                <p>{item.review}</p>
              </div>
            )
          })}
        </Slider>
    </ReviewsSection>
  );
}
