import styled from "styled-components";

import { deviceQueries } from "../../common.styled";

export const NavBlock = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0 1.5rem;
  height: var(--nav-height);
  background-color: var(--navy);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  transition: height .3s ease-in-out;

  &.small {
    height: var(--nav-scroll-height);
    box-shadow: 0 10px 30px -10px var(--navy-shadow);
  }

  .logo {
    height: 42px;
    width: 42px;
    max-width: 100%;
    vertical-align: middle;
    position: relative;

    span {
      position: absolute;
      top: calc(50% + 3px);
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--green);
    }

    #logo {
      height: 42px;
      width: 42px;
      color: var(--green);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .navbar {
    display: block;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: var(--transition);
    width: min(75vw, 400px);
    background-color: var(--light-navy);
    height: 100%;
    z-index: 10;

    ol {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      font-family: "Fira Mono", monospace;
      font-size: clamp(var(--fz-sm),4vw,var(--fz-lg));
      padding: 0;
      flex-direction: column;

      li {
        padding-left: 0;
        margin: 0 1rem 2rem;
        counter-increment: item 1;
        color: var(--lightest-slate);
        text-align: center;
        cursor: pointer;

        img {
          color: red
        }

        &:hover {
          color: var(--green);
        }

        &::before {
          content: "0" counter(item) ".";
          display: block;
          margin-right: 0;
          color: var(--green);
          margin-bottom: .5rem;
        }

        &:last-child {
          padding: 0.75rem 1rem;
          border: 1px solid var(--green);
          color: var(--green);
          border-radius: var(--border-radius);
          margin-top: 2rem;

          &::before {
            display: none;
          }
        }

        &.no-number {
          position: relative;

          ::before {
            display: none;
          }

          ul {
            display: ${props => props.isLanguageSelectorOpen ? 'block' : 'none'};
            position: absolute;
            list-style: none;
            padding: 0;
            top: calc(100% + 1rem);
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;

            li {
              border: none;
              color: white;
              background-color: var(--dark-navy);
              padding: 0.75rem 1rem;
              width: 100%;
              border-radius: 0;
              margin: 0;

              &::before {
                 display: none;
              }
            }
          }
        }
      }
    }

    &.active {
      transform: translateX(0);
    }
  }

  .ham-icon {
    height: calc(var(--hamburger-width) * 1.2);
    width: calc(var(--hamburger-width) * 1.2);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 15;
    margin-right: ${props => props.isNavOpen ? '12px' : '0'};

    &--icon {
      position: absolute;
      top: 50%;
      width: var(--hamburger-width);
      height: 2px;
      border-radius: var(--border-radius);
      background-color: var(--green);
      transition-duration: 0.22s;
      transition-property: transform;
      transition-delay: ${props => (props.isNavOpen ? `0.12s` : `0s`)};
      transform: rotate(${props => (props.isNavOpen ? `225deg` : `0deg`)});
      transition-timing-function: cubic-bezier(
        ${props => (props.isNavOpen ? `0.215, 0.61, 0.355, 1` : `0.55, 0.055, 0.675, 0.19`)}
      );

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: auto;
        right: 0;
        width: var(--hamburger-width);
        height: 2px;
        border-radius: 4px;
        background-color: var(--green);
        transition-timing-function: ease;
        transition-duration: 0.15s;
        transition-property: transform;
      }

      &::before {
        width: ${props => (props.isNavOpen ? `100%` : `120%`)};
        top: ${props => (props.isNavOpen ? `0` : `-10px`)};
        opacity: ${props => (props.isNavOpen ? 0 : 1)};
        transition: ${({ isNavOpen }) => isNavOpen ? 'var(--ham-before-active)' : 'var(--ham-before)'};
      }

      &::after {
        width: ${props => (props.isNavOpen ? `100%` : `80%`)};
        bottom: ${props => (props.isNavOpen ? `0` : `-10px`)};
        transform: rotate(${props => (props.isNavOpen ? `-90deg` : `0`)});
        transition: ${({ isNavOpen }) => (isNavOpen ? 'var(--ham-after-active)' : 'var(--ham-after)')};
      }
    }
  }

  @media ${deviceQueries.laptop} {
    padding: 0 3rem;

    .navbar {
      position: static;
      transition: var(--transition);
      width: unset;
      transform: translateX(0);
      background-color: unset;
      height: unset;
      display: block;
      line-height: normal;

      ol {
        flex-direction: row;
        font-size: var(--fz-xs);

        li {
          margin-bottom: 0;
          
          &::before {
            display: inline;
            margin-right: 0.25rem;
            margin-bottom: 0;
          }

          &:last-child {
            margin-right: 0;
            margin-top: 0;
          }
        }
      }
    }

    .ham-icon {
      display: none;
    }
  }
`;
