import { useState, useEffect, useContext } from "react";

import { languageOptions } from '../data/languages';
import { Text, LanguageContext } from '../containers/Language';

import Globe from '../assets/img/svg/globe.svg';
import ResumePDF from '../assets/downloads/resume.pdf'

import { NavBlock } from "../styled/sections/Nav.styled";

export default function Nav() {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false)

  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const { dictionary } = useContext(LanguageContext)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [])

  const toggleMobileNav = () => {
    if(window.innerWidth < 1024) {
      setIsNavOpen(!isNavOpen)
      document.body.classList.toggle('blur')
    }
  }

  const handleLanguageChange = value => userLanguageChange(value);

  const handleScroll = () => setIsPageScrolled(window.pageYOffset > 50)

  const toggleLanguageSelector = language => {
    setIsLanguageSelectorOpen(!isLanguageSelectorOpen)

    if (!language) return;

    handleLanguageChange(language)
  }

  return (
    <NavBlock isNavOpen={isNavOpen} isLanguageSelectorOpen={isLanguageSelectorOpen} className={isPageScrolled ? 'small' : 'big'}>
      <a href="#root" className="logo">
        <span>S</span>
        <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
          <title>Logo</title><g transform="translate(-8.000000, -2.000000)">
            <g transform="translate(11.000000, 5.000000)">
              <polygon id="Shape" fill="transparent" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" points="39 0 0 22 0 67 39 90 78 68 78 23"></polygon>
            </g>
          </g>
        </svg>
      </a>
      <div className={`navbar${isNavOpen ? " active" : ""}`}>
        <ol>
          <li onClick={() => toggleMobileNav()}><a href="#section-about">{dictionary.navigation.about}</a></li>
          <li onClick={() => toggleMobileNav()}><a href="#section-work">{dictionary.navigation.jobs}</a></li>
          <li onClick={() => toggleMobileNav()}><a href="#section-projects">{dictionary.navigation.projects}</a></li>
          <li onClick={() => toggleMobileNav()}><a href="#section-contact">{dictionary.navigation.contact}</a></li>
          <li className="no-number" onClick={() => toggleLanguageSelector()}>
            <img src={Globe} />
            <ul>
              {Object.entries(languageOptions).map(([id, name]) => (
                <li className="language-selector__options--item" key={id} value={id} onClick={() => toggleLanguageSelector(id)}>{name}</li>
              ))}
            </ul>
          </li>
          <li><a href={ResumePDF} download="Resume - Sylvain Depardieu" target="_blank">{dictionary.navigation.resume}</a></li>
        </ol>
      </div>
      <div className={`ham-icon${isNavOpen ? " is-open" : ""}`} onClick={() => toggleMobileNav()}>
        <div className="ham-icon--icon"></div>
      </div>
    </NavBlock>
  );
}
