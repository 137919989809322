import { SkillItemStyled } from "../styled/components/SkillItem.styled";

export default function SkillItem(props) {
  return (
    <SkillItemStyled value={props.grade}>
        <div className="skill-data">
            <span className="title">{props.title}</span>
            <span className="value">{props.grade}%</span>
        </div>
      <div className="grade-bar"></div>
    </SkillItemStyled>
  );
}
