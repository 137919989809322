import { useState, useContext, useRef } from 'react'

import { WorkSection } from "../styled/sections/Work.styled";

import { Text, LanguageContext } from "../containers/Language";

import { ReactComponent as ChevronLeft } from "../assets/img/svg/chevron-left.svg"
import { ReactComponent as ChevronRight } from "../assets/img/svg/chevron-right.svg"

export default function Work() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const worksMenu = useRef(null)

  const { dictionary } = useContext(LanguageContext)
  
  const showSlide = slide => {
    console.log(worksMenu.current.getBoundingClientRect())
    worksMenu.current.scrollLeft = slide * 120;
    if (slide < 0 || slide >= dictionary.experience.experienceItems.length) return;
    setCurrentSlide(slide)
  }

  return (
    <WorkSection id="section-work" className="work" activeSlide={currentSlide}>
      <h2 className="numbered">{dictionary.experience.sectionTitle}</h2>
      <div className="work-block">
        <div className="work-block__tabs">
          <div className="work-block__tabs--container">
            <ul ref={worksMenu}>
              {dictionary.experience.experienceItems.map((item, idx) => {
                return (
                  <li key={idx}>
                    <button onClick={() => showSlide(idx)}>
                      {item.company}
                    </button>
                  </li>
                )
              })}
            <div className="work-block__tabs--container--marker"></div>
            </ul>
          </div>
          <div className="work-block__tabs--scroll scroll-left" onClick={() => showSlide(currentSlide-1)}><ChevronLeft /></div>
          <div className="work-block__tabs--scroll scroll-right" onClick={() => showSlide(currentSlide+1)}><ChevronRight /></div>
        </div>
        <div className="work-block__content">
          {dictionary.experience.experienceItems.map((item, idx) => {
            return (
              <div key={idx} id={`work-content-item${idx}`} className={`work-block__content--item ${idx === currentSlide && "active"}`}>
                <h3 className="work-block__content--title">{item.position} <span>@ {item.company}</span></h3>
                <span className="work-block__content--timerange">{item.timerange.start} - {item.timerange.end}</span>
                <ul>
                  {item.desc.map((line, idy) => {
                    return <li key={idy}>{line}</li>
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </WorkSection>
  );
}
