import styled from "styled-components";

import { deviceQueries } from "../../common.styled";

export const SkillsSection = styled.section`
  h2 {
      counter-increment: title 2;
  }

  .skills-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @media ${deviceQueries.mobileL} {
   .skills-grid {
      grid-template-columns: repeat(2, 1fr);
   }
  }
`;
