import { createGlobalStyle } from 'styled-components';

import CalibreMedium from './assets/font/Calibre-Medium.woff2'
import CalibreRegular from './assets/font/Calibre-Regular.woff2'
import CalibreSemibold from './assets/font/Calibre-Semibold.woff2'
import SFMonoRegular from './assets/font/SFMono-Regular.woff2'

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const deviceQueries = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};
 
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Calibre";
    src: url(${CalibreRegular});
  }
  @font-face {
    font-family: "Calibre";
    src: url(${CalibreMedium});
    font-weight: 500;
  }
  @font-face {
    font-family: "Calibre";
    src: url(${CalibreSemibold});
    font-weight: 600;
  }
  @font-face {
    font-family: "SF Mono";
    src: url(${SFMonoRegular});
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--dark-slate) var(--navy);
    scroll-behavior: smooth;
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: var(--navy);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--dark-slate);
    border: 3px solid var(--navy);
    border-radius: 10px;
  }

  :root {
    --dark-navy: #020c1b;
    --navy: #0a192f;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);
    --dark-slate: #495670;
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --white: #e6f1ff;
    --green: #64ffda;
    --green-tint: rgba(100, 255, 218, 0.1);
    --pink: #f57dff;
    --blue: #57cbff;
    --font-sans: "Calibre", "Open Sans", "San Francisco", "SF Pro Text",
      -apple-system, system-ui, sans-serif;
    --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
    --fz-xxs: 0.75rem;
    --fz-xs: 0.8125rem;
    --fz-sm: 0.875rem;
    --fz-md: 1rem;
    --fz-lg: 1.125rem;
    --fz-xl: 1.25rem;
    --fz-xxl: 1.375rem;
    --fz-heading: 2rem;
    --border-radius: 0.25rem;
    --nav-height: 100px;
    --nav-scroll-height: 70px;
    --tab-height: 42px;
    --tab-width: 120px;
    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    --hamburger-width: 30px;
    --ham-before: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    --ham-before-active: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    --ham-after: bottom 0.1s ease-in 0.25s,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ham-after-active: bottom 0.1s ease-out,
      transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  body {
    margin: 0px;
    width: 100vw;
    min-height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    background-color: var(--navy);
    color: var(--slate);
    font-family: var(--font-sans);
    font-size: var(--fz-xl);
    line-height: 1.3;

    &.blur {
      overflow: hidden;

      main, footer {
        filter: blur(5px) brightness(0.7);
        pointer-events: none;
      }
    }
  }

  .page-container {
    overflow-x: hidden;
    position: relative;
    height: 100vh;
  }

  main {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1.5rem;
    transition: var(--transition);
  }

  section {
    padding: 80px 0;
  }

  .centered {
    text-align: center;
  }

  .page-content.hide {
    display: none;
  }

  h1,
  h2,
  h3 {
    font-family: var(--font-sans);
    margin: 0;
    font-weight: 600;
    color: var(--lightest-slate);
    margin-bottom: 40px;

    &.numbered {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      position: relative;
      margin: 10px 0px 40px;
      width: 100%;
      font-size: clamp(26px,5vw,var(--fz-heading));
      white-space: nowrap;

      &::before {
        content: "0" counter(title) ".";
        color: var(--green);
        font-family: var(--font-mono);
        font-size: clamp(var(--fz-md),3vw,var(--fz-xl));
        font-weight: 400;
        position: relative;
        bottom: 4px;
      }

      &::after {
        position: relative;
        bottom: 4px;
        content: '';
        display: block;
        height: 1px;
        width: 300px;
        background-color: var(--lightest-navy);
      }
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    margin: 0 0 1rem;
  }

  button,
  .button {
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 1.25rem 1.75rem;
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
  }

  button:hover,
  .button:hover {
    background-color: var(--green-tint);
  }
`

export default GlobalStyle