import { useContext } from 'react';

import { Text, LanguageContext } from '../containers/Language';

import { AboutSection } from "../styled/sections/About.styled";

import Profile from "../assets/img/profile.jpg";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../assets/img/svg', false, /\.(png|jpe?g|svg)$/));

export default function About() {
  
  const { dictionary } = useContext(LanguageContext);

  return (
    <AboutSection id="section-about">
      <span>{dictionary.hello}</span>
      <h2 className="numbered">{dictionary.about.sectionTitle}</h2>
      <div className="hero-section__block">
        <div className="hero-section__block--text">
          {dictionary.about.desc.map((item, idx) => <p key={idx}>{item}</p>)}
          <ul className="skill-list">
            {Object.values(dictionary.about.infoList).map((item, idx) => {
              if (typeof item === "string") return <li key={idx}>{item}</li>
              if (typeof item === "object") return (
                <li key={idx}>{item.text}
                  <div className='flags'>
                    {item.img.map((item, idy) => {
                      return <img key={idy} src={images[item]} />
                    })}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="hero-section__block--img">
          <div className="hero-section__block--img-container">
            <img src={Profile} alt="Profile"></img>
          </div>
        </div>
      </div>
    </AboutSection>
  );
}
