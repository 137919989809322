import { useState, useEffect } from 'react'
import GlobalStyle from "./common.styled"

import Nav from "./sections/Nav";
import Hero from "./sections/Hero";
import About from "./sections/About";
import Skills from "./sections/Skills";
import Work from "./sections/Work"
import Projects from "./sections/Projects"
import Contact from "./sections/Contact"
import Footer from "./sections/Footer"
import Reviews from "./sections/Reviews";
import Loader from "./sections/Loader";

import { LanguageProvider } from './containers/Language';

export default function App() {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 2000)
  }, [])

  return (
    <LanguageProvider>
      <GlobalStyle />
      <div className='page-container'>
        <div className={`page-content ${!isLoaded ? 'hide' : "show"}`}>
          <Nav />
          <main>
            <Hero />
            <About />
            <Skills />
            <Work />
            <Projects />
            <Reviews />
            <Contact />
          </main>
          <Footer />
        </div>
        <Loader />
      </div>
    </LanguageProvider>
  );
}