import styled from "styled-components";

import { deviceQueries } from "../../common.styled";

export const ReviewsSection = styled.section`
    padding: 0 1rem;
    
    h2 {
        margin-bottom: 2rem;
    }

    .slick-arrow {
        &::before {
            color: var(--slate);
        }
    }

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        padding: 0 .5rem;
        height: inherit !important;

        > div {
            height: 100%;
        }
    }

    .reviews-slide {
        height: 100%;
        display: flex !important;
        flex-direction: column;

        &--titleblock {
            margin-bottom: .5rem;
            padding-left: 1rem;
            display: flex;
            gap: 1rem;
            align-items: center;

            h3 {
                margin: 0;
            }

            &-icon {
                width: 2rem;
            }
        }

        p {
            background-color: var(--light-navy);
            border-radius: var(--border-radius);
            padding: 1rem;
            flex: 1;
            display: flex;
            align-items: center;
        }
    }
`;
