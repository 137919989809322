import { useContext } from "react";

import { Text, HeroSection } from "../styled/sections/Hero.styled";

import { LanguageContext } from "../containers/Language";

export default function Hero() {
  const { dictionary } = useContext(LanguageContext)

  return (
    <HeroSection>
      <div className="hero-container">
        <span className="mynameis">{dictionary.hero.titleIntro}</span>
        <h1>{dictionary.hero.mainTitle}</h1>
        <h2>{dictionary.hero.secondaryTitle}</h2>
        <p>{dictionary.hero.description}</p>
        <a href="#section-about">{dictionary.hero.button}</a>
      </div>
    </HeroSection>
  );
}
