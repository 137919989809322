import { useState, useEffect, useContext } from "react";

import { Text, LoaderSection } from "../styled/sections/Loader.styled";

import { LanguageContext } from "../containers/Language";

export default function Loader(props) {
  const { dictionary } = useContext(LanguageContext)

  
  const [isLoading, setIsLoading] = useState(true)
  const [loadValue, setLoadValue] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    const loading = setInterval(() => {
      if (loadValue >= 100 || !isLoading ) {
        clearInterval(loading);
      } else {
        setLoadValue(loadValue => loadValue+1);
      }
    }, 20)

    return () => clearInterval(loading);
  }, [loadValue, isLoading])

  return (
    <LoaderSection className={!isLoading && 'hide'}>
        <div className="body">
            <span>
                <span>
                    </span><span>
                </span>
                <span></span>
                <span></span>
            </span>
            <div className="base">
                <span></span>
                <div className="face"></div>
            </div>
        </div>
        <div className="longfazers">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <h1>{loadValue}%</h1>
    </LoaderSection>
  );
}
